import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import axios from 'axios';
import { Paper, CircularProgress, Box, Typography } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import moment from 'moment';
import 'moment/locale/fr';
import colors from '../../assets/sass/colors';
import { withAuth0 } from '@auth0/auth0-react';
import config from '../../config';
import { mapDispatchToProps, mapStateToProps } from '../../utils/redux/Mapping';
import { connect, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import StepWizard from 'react-step-wizard';
import './TripRequest.scss';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import WhereAndWhen from './WhereAndWhen/WhereAndWhen';
import PassengersAndPayment from './PassengersAndPayment/PassengersAndPayment';
import { useTranslation } from 'react-i18next';
import { TravelPreferenceType } from '../../enums/TravelPreferenceType';
import { addMinutes } from 'date-fns';
import ROUTES from '../../utils/routes';
import { PaymentType } from '../../enums/PaymentType';
import Time from '../../utils/Time';
import { PassengerAndPaymentView } from '../../enums/passengerAndPaymentViews';
import { isMobile, isTablet } from 'react-device-detect';
import { AccessTokenContext } from '../App';

const REQUEST_SOURCE = 'PASSENGER_WEB';
const LANGUAGE = navigator.language.substring(0, 2);

const TripRequest = ({
  auth0,
  currentLocation,
  originAddress,
  setOriginAddress,
  originCoords,
  setOriginCoords,
  setMapOriginCoordinates,
  setMapDestinationCoordinates,
  destAddress,
  destCoords,
  setCurrentLocation,
  operatorSelected,
  passengers,
  travelPreference,
  priceObject,
  paymentMethod
}) => {
  const accessToken = useContext(AccessTokenContext);
  const taDetails = useSelector((state) => state.operatorSelected);
  const taGeoJSON = useSelector((state) => state.taGeoJSON);
  const [travelTimeData, setTravelTimeData] = useState(null);
  const [tripRequestDate, setTripRequestDate] = useState(null);
  const [bundleId, setBundleId] = useState(uuidv4());
  const [customerId, setCustomerId] = useState();
  const [isTripRequestSubmitting, setIsTripRequestSubmitting] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const [currentPassengerAndPaymentView, setCurrentPassengerAndPaymentView] = useState(
    PassengerAndPaymentView.DEFAULT
  );
  const { t } = useTranslation('common');
  const { user } = auth0;
  const history = useHistory();
  const selectedOperator = useSelector((state) => state.operatorSelected);

  useEffect(() => {
    moment.locale(LANGUAGE);

    if (config.isDemo) {
      handleDemoMode();
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      getCustomerId();
    }
  }, [accessToken]);

  useEffect(() => {
    if (taDetails) {
      setTripRequestDate(
        addMinutes(new Date(), Time.convertTimeToMinutes(taDetails?.request_window_low))
      );
    }
  }, [taDetails]);

  useEffect(() => {
    if (operatorSelected && originCoords && destCoords) {
      const travelTime = calculateTripDuration(originCoords, destCoords);

      setTravelTimeData(travelTime);
    }
  }, [passengers, originCoords, destCoords]);

  const getCustomerId = async () => {
    try {
      const customerIdResponse = (
        await axios.post(
          `${config.blaiseApiUrl}/payment/retrieveCustomerId_v2`,
          {
            passenger_id: user.sub,
            transitAgencyId: selectedOperator.transit_agency_id
          },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
      ).data;

      if (customerIdResponse.connected_customer_id) {
        setCustomerId(customerIdResponse.connected_customer_id);
      }
    } catch (err) {
      console.log('TripRequest::getCustomerId', err);
    }
  };

  const handleDemoMode = () => {
    // In demo mode, the user's location is hardcoded to Laval (college montmorency), this includes field autofill and origin_coords
    setCurrentLocation('45.559099,-73.719737');
    setOriginAddress("Collège Montmorency, 475 Boulevard de l'Avenir, Laval, QC H7N");
    setOriginCoords([45.559099, -73.719737]);
  };

  const calculateTripDuration = async (originCoords, destCoords) => {
    try {
      const reqBody = {
        origin: {
          lat: originCoords.lat,
          lon: originCoords.lng
        },
        destination: {
          lat: destCoords.lat,
          lon: destCoords.lng
        },
        transitAgencyId: operatorSelected.transit_agency_id,
        passengerId: user.sub,
        passengerDemand: passengers
      };

      const travelTime = (
        await axios.post(`${config.blaiseApiUrl}/calculateTravelTime`, reqBody, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      ).data;

      if (travelTime.finalTravelTime && typeof travelTime.finalTravelTime === 'number') {
        setTravelTimeData(travelTime);
      } else {
        throw new Error('Returned travel time is invalid');
      }
    } catch (err) {
      openSnackbar(t('console.originDestinationFar'), colors.red);
      console.log(err);
    }
  };

  const validateForm = () => {
    setIsTripRequestSubmitting(true);

    // TODO: Rewrite this logic. It was copy/pasted from old TripRequest.
    let dep = new Date(tripRequestDate);
    if (travelPreference === TravelPreferenceType.ARRIVE_BY) {
      dep = Time.subtractFromDatetime(new Date(tripRequestDate), {
        seconds: travelTimeData.finalTravelTime,
        datetimelocal: false
      });
    }

    let tripMinimum = new Date();
    tripMinimum = Time.addToDatetime(tripMinimum, { hours: 0, datetimelocal: false }); // TODO: What does this do even?
    try {
      if (travelPreference === TravelPreferenceType.ARRIVE_BY && dep < tripMinimum) {
        throw new Error(t('tripReq.arrivalError'));
      }

      if (travelPreference === TravelPreferenceType.DEPART_AT && dep < tripMinimum) {
        throw new Error(t('tripReq.departureError'));
      }

      // No passengers selected
      const noPassengers = Object.keys(passengers).every((passengerType) => {
        return passengers[passengerType].length === 0;
      });

      if (noPassengers) {
        throw new Error(t('tripReq.no_passengers_selected'));
      }

      let arr = new Date(dep); // need to reassign so it copies by value
      dep = new Date(dep);
      arr = Time.addToDatetime(arr, {
        seconds: travelTimeData.finalTravelTime,
        datetimelocal: true
      });
      dep = Time.getDatetimeLocal(dep);

      createTripRequest(dep, arr);
    } catch (error) {
      openSnackbar(error.message, colors.red);
      setIsTripRequestSubmitting(false);
    }
  };

  const createTripRequest = async (dep, arr) => {
    const now = new Date();
    const tzOffset = now.getTimezoneOffset();

    if (config.isDemo) {
      history.push(ROUTES.trips);
    } else {
      try {
        // TODO: Rewrite this logic. It was copy/pasted from old TripRequest.
        const dep_date = dep.split('T')[0];
        const dep_earliest_time = dep.split('T')[1];
        const arr_date = arr.split('T')[0];
        const arr_latest_time = arr.split('T')[1];

        const dep_final = Time.changeOpZone(dep_date, dep_earliest_time, tzOffset);
        const arr_final = Time.changeOpZone(arr_date, arr_latest_time, tzOffset);

        const time = operatorSelected.service_time.split(':');
        const service_time = +time[0] * 60 * 60 + +time[1] * 60 + +time[2];

        const dep_req = `${dep_final.date}T${dep_final.time}`;
        const arr_req = `${arr_final.date}T${arr_final.time}`;

        const reqBody = {
          depDatetime: dep_req,
          arrDatetime: arr_req,
          origin_address: [originCoords.lat, originCoords.lng],
          originAddressLabel: originAddress,
          destination_address: [destCoords.lat, destCoords.lng],
          destinationAddressLabel: destAddress,
          transit_agency_id: operatorSelected.transit_agency_id,
          priority: travelPreference === TravelPreferenceType.DEPART_AT ? 0 : 1,
          demand: passengers,
          service_time: service_time,
          paymentMethodId: paymentMethod.id,
          paymentType: paymentMethod.type,
          customerId: customerId,
          tripPrice: paymentMethod.id === PaymentType.PASS ? 0 : priceObject?.price,
          bundleId: bundleId,
          requestMethod: REQUEST_SOURCE
        };

        await axios.post(`${config.blaiseApiUrl}/passengers/${user.sub}/requests_v2`, reqBody, {
          headers: { Authorization: `Bearer ${accessToken}` }
        });

        openSnackbar(t('console.requestSuccess'), colors.blaiseGreen);
        setBundleId(uuidv4()); // Must generate new uuid in case multiple trips are submitted in one session
        setIsTripRequestSubmitting(false);
        history.push(ROUTES.trips);
      } catch (err) {
        console.log('TripRequest::createTripRequest -', err);

        let message = t('console.requestError');

        if (err.response) {
          switch (err.response.data.error.cause) {
            case 'origin':
              message = t('tripReq.originOutsideAreaError');
              break;
            case 'destination':
              message = t('tripReq.destOutsideAreaError');
              break;
            case 'originAndDestination':
              message = t('tripReq.orginAndDestOutsideAreaError');
              break;
            case 'late':
              message = t('tripReq.departureTooLateError');
              break;
            case 'early':
              message = t('tripReq.departureTooEarlyError');
              break;
            default:
              message = t('console.requestError');
          }
        }

        openSnackbar(message, colors.red);
        setIsTripRequestSubmitting(false);
      }
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const istripRequestContainerFullScreen =
    (isMobile || isTablet) &&
    (currentPassengerAndPaymentView === PassengerAndPaymentView.ADD_PASSENGERS ||
      currentPassengerAndPaymentView === PassengerAndPaymentView.PAYMENT_METHOD);

  return (
    <>
      <CustomSnackbar
        open={snackbar.open}
        message={snackbar.message}
        snackbarColor={snackbar.color}
        onClose={closeSnackbar}
      />

      <ThemeProvider theme={theme}>
        {!taDetails && !taGeoJSON && (
          <Box className="loading-transit-agency-info">
            <CircularProgress />
            <Typography>{t('tripDetails.loading')}</Typography>
          </Box>
        )}

        {currentLocation && operatorSelected && (
          <Paper
            elevation={3}
            className="trip-request-container"
            style={{
              position: istripRequestContainerFullScreen && 'absolute',
              top: istripRequestContainerFullScreen && '0',
              height: istripRequestContainerFullScreen && `calc(${window.innerHeight}px - 48px)` // 48px for top navbar.
            }}
          >
            <StepWizard>
              <WhereAndWhen
                travelTimeData={travelTimeData}
                tripRequestDate={tripRequestDate}
                setTripRequestDate={setTripRequestDate}
                setMapOriginCoordinates={setMapOriginCoordinates}
                setMapDestinationCoordinates={setMapDestinationCoordinates}
              />
              <PassengersAndPayment
                openSnackbar={openSnackbar}
                validateForm={validateForm}
                isTripRequestSubmitting={isTripRequestSubmitting}
                currentPassengerAndPaymentView={currentPassengerAndPaymentView}
                setCurrentPassengerAndPaymentView={setCurrentPassengerAndPaymentView}
              />
            </StepWizard>
          </Paper>
        )}
      </ThemeProvider>
    </>
  );
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.blaiseGreen
    },
    secondary: {
      main: colors.blaiseLightGray
    }
  },
  overrides: {
    MuiPickersToolbarText: {
      toolbarTxt: { color: colors.blaiseLightGray },
      toolbarBtnSelected: { color: 'white' }
    },
    MuiPickerDTTabs: {
      tabs: { color: 'white' }
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withAuth0(TripRequest)));
