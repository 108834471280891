import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Box, ButtonGroup, IconButton } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import colors from '../../assets/sass/colors';
import './ViewRide.scss';
import RidePending from '../../components/RidePending/RidePending';
import RideUpcoming from '../../components/RideUpcoming/RideUpcoming';
import { mapDispatchToProps, mapStateToProps } from '../../utils/redux/Mapping';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import DownloadTheAppQRCode from '../../components/DownloadTheAppQRCode/DownloadTheAppQRCode';

const ViewRide = ({ t }) => {
  const [renderConfirmed, setRenderConfirmed] = useState(false);
  const [futureColor, setFutureColor] = useState([colors.blaisePurple, colors.white]);
  const [pastColor, setPastColor] = useState(['', colors.blaisePurple]);
  const [isQRCodePopupOpen, setIsQRCodePopupOpen] = useState(true);

  //This is for the buttons at the top, to switch between the past trips and future trips.
  //If renderPast is true, it will render the past trips. Page loads with renderPast as false
  const switchPage = (button) => {
    if (button === 'future') {
      setRenderConfirmed(false);
      setFutureColor([colors.blaisePurple, colors.white]);
      setPastColor(['', colors.blaisePurple]);
    } else if (button === 'past') {
      setRenderConfirmed(true);
      setFutureColor(['', colors.blaisePurple]);
      setPastColor([colors.blaisePurple, colors.white]);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      style={{ height: '90%' }}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        pt={2}
        style={{ width: '100%' }}
      >
        <ButtonGroup
          size="small"
          aria-label="small outlined button group"
          style={{ marginBottom: '10px' }}
        >
          <Button
            className="roundedButton"
            onClick={() => switchPage('future')}
            style={{
              backgroundColor: futureColor[0],
              color: futureColor[1],
              width: '120px'
            }}
          >
            {t('viewTrips.pendingTrips')}
          </Button>
          <Button
            className="roundedButton2"
            onClick={() => switchPage('past')}
            style={{ backgroundColor: pastColor[0], color: pastColor[1], width: '120px' }}
          >
            {t('viewTrips.comingTrips')}
          </Button>
        </ButtonGroup>
        <Box>{!renderConfirmed && <RidePending />}</Box>
        <Box>{renderConfirmed && <RideUpcoming />}</Box>
      </Box>
      {isQRCodePopupOpen && (
        <Box className="download-the-app-popup-container">
          <IconButton
            onClick={() => setIsQRCodePopupOpen(false)}
            className="close-popup"
            aria-label={t('qrCode.close_popup')}
          >
            <FontAwesomeIcon icon={faTimes} color={colors.gray400} size="lg" />
          </IconButton>
          <DownloadTheAppQRCode />
        </Box>
      )}
    </Box>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(withRouter(ViewRide)));
