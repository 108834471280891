import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { addMinutes } from 'date-fns';
import config from '../../config';
import MapView from '../MapView/MapView';
import { useHistory } from 'react-router';
import ROUTES from '../../utils/routes';
import { AccessTokenContext } from '../App';
import { datetimeFormat } from '../../utils/dateTimeFormats';

const TICK_MS = 1000; //Interval between ticks
const PROCESSING_TIME_SECONDS = 120;
const PROCESSING_TIME_MINUTES = 2;

let tick;
const RidePending = ({ t }) => {
  const [rideList, setRideList] = useState([]);
  const [refreshing, setRefreshing] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const accessToken = useContext(AccessTokenContext);
  const history = useHistory();
  const { user } = useAuth0();

  const filterRides = (rides) => {
    if (!rides) return;

    return rides.filter((ride) => {
      // reject accepted trips outright
      if (ride.is_accepted) {
        return false;
      }

      const processingTime = addMinutes(new Date(ride.request_date), PROCESSING_TIME_MINUTES);

      if (processingTime >= new Date()) {
        return true;
      }

      return false;
    });
  };

  const tickActions = () => {
    setCurrentDate(new Date());
    setRideList((prevState) => filterRides(prevState));
  };

  useEffect(() => {
    tick = setInterval(tickActions, TICK_MS);

    if (user) {
      (async () => {
        //Get all the trip requests that a passenger has submitted
        const rides = (
          await axios.get(`${config.blaiseApiUrl}/passengers/${user.sub}/requests`, {
            headers: { Authorization: `Bearer ${accessToken}` }
          })
        ).data;

        setRideList(filterRides(rides));
        setRefreshing(false);
      })();
    }

    return () => {
      clearTimeout(tick);
    };
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {refreshing && (
        <div>
          <CircularProgress aria-label="Progress Icon" aria-busy="true" />
        </div>
      )}
      {rideList.length === 0 && !refreshing && (
        <Box flexDirection="column" alignItems="center" display="flex" m={2}>
          <p>{t('comingTrips.noPendingTrips')}</p>
        </Box>
      )}
      {rideList.length > 0 && !refreshing && (
        <Box mt={1} mb={2}>
          {rideList.map((ride) => (
            <div
              key={`key${ride.trip_request_id}`}
              onClick={() =>
                history.push(`${ROUTES.tripDetails}?rideID=${ride.trip_request_id}&type=pending`)
              }
              onKeyDown={() =>
                history.push(`${ROUTES.tripDetails}?rideID=${ride.trip_request_id}&type=pending`)
              }
              role="button"
              tabIndex="0"
              style={{
                padding: 10,
                margin: 10,
                borderRadius: 10,
                elevation: 4,
                boxShadow: '3px 3px 3px 3px #ccc'
              }}
            >
              <Box display="flex" justifyContent="space-between" mx={2}>
                <div style={{ fontWeight: 'bold' }}>
                  {datetimeFormat(new Date(ride.request_date))}
                </div>
                <div style={{ fontWeight: 'bold' }}>
                  {' '}
                  {t('viewTrips.confirmationIn')}{' '}
                  {Math.round(
                    PROCESSING_TIME_SECONDS - (currentDate - new Date(ride.request_date)) / 1000
                  ) + ' s'}
                </div>
              </Box>
              <MapView ride={ride} />
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default withTranslation('common')(RidePending);
