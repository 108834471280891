import React, { useContext, useState } from 'react';
import Rating from '@material-ui/lab/Rating';
import { withTranslation } from 'react-i18next';
import { Box, Button, TextField, withStyles } from '@material-ui/core';
import colors from '../../assets/sass/colors';
import './TripRating.scss';
import { mapDispatchToProps, mapStateToProps } from '../../utils/redux/Mapping';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../config';
import { useHistory } from 'react-router';
import ROUTES from '../../utils/routes';
import { AccessTokenContext } from '../../components/App';

const StyledRating = withStyles({
  iconFilled: {
    color: colors.blaiseGreen
  },
  iconEmpty: {
    color: colors.blaiseLightGray
  }
})(Rating);

const TripRating = ({ ride, t }) => {
  const [rating, setRating] = useState(null);
  const [comment, setComment] = useState('');
  const accessToken = useContext(AccessTokenContext);
  const history = useHistory();

  const handleChange = (event) => {
    setRating(parseInt(event.target.value));
    // When the user changes the rating, we want to remove any comments they may have selected
    if (event.target.name === 'rating') {
      // Changing all the buttons back to their original unselected colors
      for (let i = 1; i < 12; i++) {
        if (document.getElementById(`b${i}`)) {
          document.getElementById(`b${i}`).style.backgroundColor = colors.blaiseLightGray;
        }
      }
    }
  };

  const onChange = (event) => {
    setComment(event.target.value);
  };

  const handleSkipFeedback = () => {
    history.push(ROUTES.history);
  };

  const submitForm = async () => {
    const reqBody = {
      comment,
      rating
    };

    try {
      await axios
        .put(`${config.blaiseApiUrl}/rides/${ride.ride_proposal_id}/actualRide`, reqBody, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(history.push(ROUTES.history));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        alignContent="center"
        className="ratingContainer"
        mx={3}
        style={{ alignItems: 'center', width: '100%' }}
      >
        <legend>{t('rating.title')}</legend>

        <StyledRating
          style={{ fontSize: '3em' }}
          name="rating"
          id="rating"
          value={rating}
          onChange={handleChange}
        />

        <div
          style={{ paddingTop: 20, width: '100%', alignContent: 'center', alignItems: 'center' }}
        >
          <TextField
            style={{ backgroundColor: colors.blaiseLightGreen }}
            onChange={onChange}
            multiline={true}
            rows="7"
            size="medium"
            fullWidth={true}
          ></TextField>
        </div>
        <br />
        <div style={{ paddingTop: 20 }}>
          {!rating && comment.length === 0 && (
            <Button type="submit" color="primary" variant="contained" onClick={handleSkipFeedback}>
              {t('rating.skip')}
            </Button>
          )}

          {(rating || comment) && (
            <Button type="submit" color="primary" variant="contained" onClick={submitForm}>
              {t('rating.submit')}
            </Button>
          )}
        </div>
      </Box>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TripRating));
