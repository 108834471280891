import React, { useState, useEffect, useContext } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { faChevronLeft, faPlus, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../assets/sass/colors';
import './PassengersAndPayment.scss';
import AddPassenger from '../AddPassenger/AddPassenger';
import Wallet from '../../Wallet/Wallet';
import { PassengerAndPaymentView } from '../../../enums/passengerAndPaymentViews';
import { useTranslation } from 'react-i18next';
import { mapDispatchToProps, mapStateToProps } from '../../../utils/redux/Mapping';
import { connect } from 'react-redux';
import axios from 'axios';
import config from '../../../config';
import { useAuth0 } from '@auth0/auth0-react';
import { PaymentType } from '../../../enums/PaymentType';
import { AccessTokenContext } from '../../App';

const LANGUAGE = navigator.language.substring(0, 2);

// TODO: Deconstruct all props. Do not ...props.
const PassengersAndPayment = ({
  previousStep,
  openSnackbar,
  currentPassengerAndPaymentView,
  setCurrentPassengerAndPaymentView,
  ...props
}) => {
  const [passengerTypes, setPassengerTypes] = useState([]);
  const [accommodationTypes, setAccommodationTypes] = useState([]);
  const { t } = useTranslation('common');
  const { user } = useAuth0();
  const accessToken = useContext(AccessTokenContext);

  useEffect(() => {
    if (props.operatorSelected?.transit_agency_id) {
      getPassengerDetails();
    }
  }, [props.operatorSelected]);

  useEffect(() => {
    if (props.originCoords && props.destCoords) {
      calculateTripPrice(props.paymentMethod);
    }
  }, [props.paymentMethod, props.passengers]);

  const getPassengerDetails = async () => {
    const { transit_agency_id } = props.operatorSelected;

    // Get Passenger Types
    const passengerTypes = (
      await axios.get(
        `${config.blaiseApiUrl}/transitagencies/${transit_agency_id}/passengerTypes`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      )
    ).data;
    setPassengerTypes(passengerTypes);

    // Get Accommodation Types
    const accommodationTypesRaw = (
      await axios.get(`${config.blaiseApiUrl}/allTransitAgencies/accomodations`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    ).data;

    const accommodationTypes = accommodationTypesRaw.map((accommodationType) => {
      return { ...accommodationType, selected: false };
    });

    setAccommodationTypes(accommodationTypes);

    // Set Global Passengers State If Null
    if (!props.passengers) {
      const userPassengerType = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transit_agency_id}/passengerType`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      let passengerObj = {};

      for (const passengerType of passengerTypes) {
        passengerObj[passengerType.type] = [];
      }

      try {
        passengerObj[userPassengerType.type]?.push({
          accommodationTypes,
          isExpanded: true,
          type: userPassengerType.type
        });

        props.setPassengers(passengerObj);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const calculateTripPrice = async (paymentMethod) => {
    const passengers = props.passengers;
    const transitAgencyId = props.operatorSelected.transit_agency_id;
    const originCoords = props.originCoords;
    const destCoords = props.destCoords;

    try {
      const departTrip = {
        departOriginLat: originCoords.lat,
        departOriginLon: originCoords.lng,
        departDestLat: destCoords.lat,
        departDestLon: destCoords.lng
      };

      const request = {
        origin_address: originCoords,
        destination_address: destCoords,
        transitAgencyId,
        payment_type: paymentMethod,
        demand: passengers,
        bundleAddresses: [departTrip]
      };

      const priceObject = (
        await axios.post(`${config.blaiseApiUrl}/pricing/calculateTripPrice`, request, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
      ).data;

      props.setPriceObject(priceObject);
    } catch (error) {
      throw new Error(error);
    }
  };

  const getPassengerCount = (format) => {
    let count = 0;

    if (props.passengers) {
      Object.values(props.passengers).map((passengerType) => {
        count += passengerType.length;
      });
    }

    if (format) {
      return count === 1 ? `1 ${t('tripReq.passenger')}` : `${count} ${t('tripReq.passengers')}`;
    }

    return count;
  };

  const getPaymentMethodText = () => {
    // If a payment method is selected -> return its label
    if (props.paymentMethod) {
      if (props.paymentMethod.card) {
        return `${
          props.paymentMethod.card.brand.charAt(0).toUpperCase() +
          props.paymentMethod.card.brand.slice(1)
        } ****${props.paymentMethod.card.last4}`;
      }

      return props.paymentMethod.translation;
    }

    return t('payment.select_payment_method');
  };

  const translatePassengerType = (passengerType) => {
    const passType = passengerTypes.find((element) => element.type === passengerType);
    return LANGUAGE === 'en' ? passType?.english : passType?.french;
  };

  return (
    <>
      {/* Default view */}
      {currentPassengerAndPaymentView === PassengerAndPaymentView.DEFAULT && (
        <Box className="passenger-and-payment-container">
          <Box className="mobile-scroll-container">
            <Box className="top-container">
              {/* Top nav */}
              <Button
                disabled={props.isTripRequestSubmitting}
                onClick={previousStep}
                style={{ marginBottom: '1rem', fontWeight: '500' }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: '0.75rem' }}
                  color={colors.gray800}
                  icon={faChevronLeft}
                  size="1x"
                />
                {t('labels_reusable.back')}
              </Button>

              {/* Passenger */}
              <Button
                onClick={() =>
                  setCurrentPassengerAndPaymentView(PassengerAndPaymentView.ADD_PASSENGERS)
                }
                disabled={props.isTripRequestSubmitting}
                className="passengers-payment-button"
              >
                <Typography>{getPassengerCount(true)}</Typography>
                <FontAwesomeIcon
                  style={{ marginRight: '12px' }}
                  color={colors.gray800}
                  icon={faPlus}
                  size="1x"
                />
              </Button>

              {/* Payment method */}
              <Button
                onClick={() =>
                  setCurrentPassengerAndPaymentView(PassengerAndPaymentView.PAYMENT_METHOD)
                }
                disabled={props.isTripRequestSubmitting}
                className="passengers-payment-button"
              >
                <Typography>{getPaymentMethodText()}</Typography>
                <FontAwesomeIcon
                  style={{ marginRight: '12px' }}
                  color={colors.gray800}
                  icon={faChevronRight}
                  size="1x"
                />
              </Button>
            </Box>

            {/* Summary */}
            {getPassengerCount() > 0 && (
              <Box className="summary-container">
                <Typography className="summary-header">{t('payment.summary')}</Typography>
                <Box className="passengers-container">
                  {props.passengers &&
                    Object.keys(props.passengers).map((passengerType, idx) => {
                      const passengerTypeCount = props.passengers[passengerType].length;

                      if (passengerTypeCount > 0) {
                        return (
                          <Box key={idx} className="summary-item">
                            <Typography>{translatePassengerType(passengerType)}</Typography>
                            <Typography>{`${passengerTypeCount}x`}</Typography>
                          </Box>
                        );
                      }
                    })}
                </Box>
                <hr />
                {props?.paymentMethod?.type && (
                  <Box className="summary-item">
                    <Typography className="total">{t('payment.total')}</Typography>
                    <Typography className="total">
                      {props.paymentMethod?.id === PaymentType.PASS ? (
                        <>{t('tripReq.free')}</>
                      ) : (
                        <>${(props.priceObject?.price / 100).toFixed(2)}</>
                      )}
                    </Typography>
                  </Box>
                )}
                <Typography className="no-show-penalty">
                  {t('tripReq.no_show_penalties_may_apply')}
                </Typography>
              </Box>
            )}
          </Box>

          <Box className="next-button">
            <Button
              onClick={props.validateForm}
              disabled={
                !props?.originAddress ||
                !props?.destAddress ||
                !props?.paymentMethod?.type || // There is an origin, destination and payment method selected
                (props.paymentMethod?.id === PaymentType.TICKETS &&
                  props.priceObject?.price / 100 > props.paymentMethod?.passengerBalance) || // Passenger has enough ticket balance to pay for trip
                props.isTripRequestSubmitting // Trip request is submitting
              }
              color="primary"
              variant="contained"
            >
              {props.paymentMethod?.id === PaymentType.TICKETS &&
              props.priceObject?.price / 100 > props.paymentMethod?.passengerBalance ? (
                <>{t('console.insufficientFundsError')}</>
              ) : (
                <>{t('labels_reusable.request')} </>
              )}
            </Button>
          </Box>
        </Box>
      )}

      {/* Add passengers */}
      {currentPassengerAndPaymentView === PassengerAndPaymentView.ADD_PASSENGERS && (
        <AddPassenger
          setCurrentPassengerAndPaymentView={setCurrentPassengerAndPaymentView}
          passengerTypes={passengerTypes}
          accommodationTypes={accommodationTypes}
          translatePassengerType={translatePassengerType}
          openSnackbar={openSnackbar}
        />
      )}

      {/* Payment method */}
      {currentPassengerAndPaymentView === PassengerAndPaymentView.PAYMENT_METHOD && (
        <Wallet
          viewFrom="tripRequest"
          paymentMethod={props.paymentMethod}
          setPaymentMethod={props.setPaymentMethod}
          setCurrentPassengerAndPaymentView={setCurrentPassengerAndPaymentView}
        />
      )}
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PassengersAndPayment);
