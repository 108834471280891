import React, { useState, useEffect, useContext } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { addMinutes } from 'date-fns';
import config from '../../config';
import MapView from '../MapView/MapView';
import ROUTES from '../../utils/routes';
import { useHistory } from 'react-router';
import { AccessTokenContext } from '../App';
import { datetimeFormat } from '../../utils/dateTimeFormats';

const RideUpcoming = ({ t }) => {
  const [rideList, setRideList] = useState([]);
  const [refreshing, setRefreshing] = useState(true);
  const accessToken = useContext(AccessTokenContext);
  const { user } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const date = addMinutes(new Date(), -30).getTime() / 1000;
      const rides = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/rides?time=${date}&show_cancelled=0&show_unconfirmed=1`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      setRideList(rides);
      setRefreshing(false);
    })();
  }, []);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {refreshing && (
        <div>
          <CircularProgress aria-label="Progress Icon" aria-busy="true" />
        </div>
      )}
      {rideList.length === 0 && !refreshing && (
        <Box flexDirection="column" alignItems="center" display="flex" m={2}>
          <p>{t('comingTrips.noComingTrips')}</p>
        </Box>
      )}
      {rideList !== [] && !refreshing && (
        <Box mt={1} mb={2}>
          {rideList.map((ride) => (
            <div
              key={`key${ride.ride_proposal_id}`}
              onClick={() =>
                history.push(`${ROUTES.tripDetails}?rideID=${ride.ride_proposal_id}&type=upcoming`)
              }
              onKeyDown={() =>
                history.push(`${ROUTES.tripDetails}?rideID=${ride.ride_proposal_id}&type=upcoming`)
              }
              role="button"
              tabIndex="0"
              style={{
                padding: 10,
                margin: 10,
                borderRadius: 10,
                elevation: 4,
                boxShadow: '3px 3px 3px 3px #ccc'
              }}
            >
              <Box display="flex" justifyContent="space-between" mx={2}>
                <div style={{ fontWeight: 'bold' }}>
                  {datetimeFormat(new Date(ride.departure_time))}
                </div>
              </Box>
              <MapView ride={ride} />
            </div>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default withTranslation('common')(RideUpcoming);
