import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './SidebarItem.scss';
import { mapStateToProps, mapDispatchToProps } from '../../utils/redux/Mapping';
import { connect } from 'react-redux';
import { isMobile, isTablet } from 'react-device-detect';

const SideBarItem = ({ label, route, icon, setSidebarOpen, minimized, setIsMobileDrawerOpen }) => {
  const location = useLocation();
  const isActive = location.pathname === route;

  const selectLinkOnMobile = () => {
    setSidebarOpen(false);
    setIsMobileDrawerOpen(false);
  };

  return (
    <Link
      to={route}
      style={{ textDecoration: 'none' }}
      onClick={() => (isMobile || isTablet ? selectLinkOnMobile() : null)}
    >
      <div className={`item ${isActive ? 'active' : ''}`}>
        <div className={!minimized && 'itemIcon'}>{icon}</div>
        {!minimized && <span className="itemLabel">{label}</span>}
      </div>
    </Link>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBarItem);
