import React from 'react';
import { Modal, Box, Button, TextField } from '@material-ui/core';
import { WhenAndWhereMobileView } from '../../../enums/whenAndWhereMobileViews';
import './MobileSearchAddressModal.scss';
import AddressSearchResult from '../AddressSearchResult/AddressSearchResult';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../assets/sass/colors';
import { faTimes, faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

function MobileSearchAddressModal({
  open,
  setMobileView,
  searchType,
  originAddress,
  setOriginAddress,
  destAddress,
  setDestAddress,
  addressResults,
  setAddressResults,
  updateMapCoords,
  handleInputChange
}) {
  const { t } = useTranslation('common');

  const clearInput = () => {
    searchType === 'origin' ? setOriginAddress('') : setDestAddress('');
  };

  return (
    <Modal
      open={open}
      onClose={() => setMobileView(WhenAndWhereMobileView.DEFAULT)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box className="modal-container">
        <Box className="close-button-container">
          <Button
            onClick={() => setMobileView(WhenAndWhereMobileView.DEFAULT)}
            className="close-button"
          >
            <FontAwesomeIcon color={colors.black} icon={faTimes} size="lg" />
          </Button>
        </Box>

        {/* Address search input */}
        <Box
          style={{
            position: 'relative',
            padding: '0 20px 20px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {searchType === 'origin' ? (
            <FontAwesomeIcon
              color={colors.blaisePurple}
              icon={faCircle}
              size="lg"
              style={{ marginRight: '10px' }}
            />
          ) : (
            <FontAwesomeIcon
              color={colors.blaiseRed}
              icon={faMapMarkerAlt}
              size="lg"
              style={{ marginRight: '10px' }}
            />
          )}
          <TextField
            label={searchType === 'origin' ? t('tripReq.origin') : t('tripReq.destination')}
            variant="outlined"
            fullWidth={true}
            value={searchType === 'origin' ? originAddress : destAddress}
            onChange={(e) => {
              searchType === 'origin'
                ? setOriginAddress(e.target.value)
                : setDestAddress(e.target.value);
              handleInputChange(e.target.value);
            }}
            /* Styles the wrapper */
            style={{ height: '44px' }}
            /* Styles the input component */
            InputProps={{
              style: {
                height: '44px',
                padding: '0 14px'
              },
              endAdornment: (
                <Button className="clear-input" onClick={clearInput}>
                  <FontAwesomeIcon color={colors.blaiseGray} icon={faTimes} size="lg" />
                </Button>
              )
            }}
            InputLabelProps={{
              style: {
                top: '-5px'
              }
            }}
            inputRef={(input) => input && input.focus()}
          />
        </Box>

        {/* Address Results */}
        {addressResults.length > 0 && (
          <Box className="address-search-result-container">
            {addressResults.map((address, index) => (
              <AddressSearchResult
                key={index}
                address={address}
                searchType={searchType}
                setOriginAddress={setOriginAddress}
                setDestAddress={setDestAddress}
                setAddressResults={setAddressResults}
                updateMapCoords={updateMapCoords}
                setMobileView={setMobileView}
              />
            ))}
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default MobileSearchAddressModal;
