import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Avatar,
  Accordion,
  AccordionSummary,
  Button,
  List,
  ListItem,
  CircularProgress
} from '@material-ui/core';
import { BrowserView, MobileView, isIOS, isDesktop } from 'react-device-detect';
import AppStoreImg from '../../assets/images/app-store-badge.png';
import GooglePlayImg from '../../assets/images/google-play-badge.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faRoute,
  faBus,
  faWallet,
  faUser,
  faSignOutAlt,
  faArrowLeft,
  faTimes,
  faMobileAlt,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import logoB from '../../assets/images/blaise_logo_b.png';
import ROUTES from '../../utils/routes';
import SideBarItem from './SidebarItem';
import config from '../../config';
import colors from '../../assets/sass/colors';
import './ExpandedSidebar.scss';
import { MountAnimation } from '../MountAnimation/MountAnimation';
import axios from 'axios';
import { AccessTokenContext } from '../App';
import { isMobile, isTablet } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

const ICON_COLOR = colors.gray500;
const ICON_SIZE = '1x';

const ExpandedSidebar = ({
  operatorSupportLink,
  handleCollapse,
  user,
  handleLogout,
  helpRef,
  aboutUsRef,
  open,
  setOpen,
  operatorList,
  isLoadingOperatorList,
  selectedOperator,
  setOperator,
  setIsMobileDrawerOpen,
  setSelectedOperator
}) => {
  const [onMountAnimation, setOnMountAnimation] = useState(false);
  const [secretAccessToTA, setSecretAccessToTA] = useState(false);
  const [secretNumber, setSecretNumber] = useState(null);
  const accessToken = useContext(AccessTokenContext);
  const { t } = useTranslation('common');
  const operatorSelected = useSelector((state) => state.operatorSelected);
  const dispatch = useDispatch();

  useEffect(() => {
    setOnMountAnimation(true);
  }, []);

  const handleClick = async (item) => {
    if (item) {
      await axios.put(
        `${config.blaiseApiUrl}/passengers/${user.sub}/linkPBalanceToTA/${item.transit_agency_id}`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      await axios.put(
        `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${item.transit_agency_id}/linkPassengerToTA`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      setSelectedOperator(item);
      setOpen(false);
      if (isMobile || isTablet) {
        setIsMobileDrawerOpen(false);
      }
    }
  };

  const checkIfSecretAccessIsAllowed = (secretNum, item) => {
    setTimeout(() => {
      if (secretNumber === secretNum && secretAccessToTA) {
        setOperator(item);
        handleClick(item);
      }
    }, 10000);
  };

  const selectOperator = (operator) => {
    setOperator(operator, true);
    handleClick(operator);
    dispatch({ type: 'SET_ORIGIN_ADDRESS', payload: '' });
    dispatch({ type: 'SET_ORIGIN_COORDS', payload: '' });
    dispatch({ type: 'SET_DEST_ADDRESS', payload: '' });
    dispatch({ type: 'SET_DEST_COORDS', payload: '' });
  };

  return (
    <>
      <MountAnimation isVisible={onMountAnimation}>
        <Box className={'expanded-sidebar-container'}>
          <Box className="sidebarTop">
            <Box className="logoWrapper">
              <img className="logo" src={logoB} alt="Blaise Transit" />
              <h1 className="companyName">blaise</h1>
            </Box>
            {isDesktop ? (
              <Button className="collapseBtn" onClick={() => handleCollapse()}>
                <FontAwesomeIcon icon={faArrowLeft} color={ICON_COLOR} size={ICON_SIZE} />
              </Button>
            ) : (
              <Button className="collapseBtn" onClick={() => setIsMobileDrawerOpen(false)}>
                <FontAwesomeIcon icon={faTimes} color={ICON_COLOR} size={ICON_SIZE} />
              </Button>
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            style={{ height: '182px' }}
          >
            {operatorList && (
              <>
                <Typography style={{ marginBottom: '1rem' }}>
                  {t('tripReq.serviceProvidedBy')} {selectedOperator?.transit_agency_name}
                </Typography>
                <Avatar
                  src={`${config.blaiseApiUrl}${selectedOperator?.logo_path}`}
                  alt={`${selectedOperator?.transit_agency_name} logo`}
                  style={{ height: '72px', width: '72px' }}
                />
                <Typography>{selectedOperator?.transit_agency_name}</Typography>
                <Button
                  onClick={() => setOpen((prevState) => !prevState)}
                  onKeyDown={() => setOpen((prevState) => !prevState)}
                  style={{ textDecoration: 'underline' }}
                >
                  {t('navbar.change')}
                  {operatorList && operatorList.length > 0 && open && (
                    <>
                      <List
                        style={{
                          backgroundColor: colors.blaiseLightGreen,
                          position: 'absolute',
                          top: '44px',
                          zIndex: 1
                        }}
                      >
                        {operatorList.map((operator) => {
                          return (
                            <ListItem
                              key={operator.transit_agency_id}
                              onMouseDown={(e) => {
                                if (e.button === 2) {
                                  const secretNum = Math.floor(Math.random() * 10000);
                                  setSecretAccessToTA(true);
                                  setSecretNumber(secretNum);
                                  checkIfSecretAccessIsAllowed(secretNum, operator);
                                }
                              }}
                              onMouseUp={() => {
                                setSecretAccessToTA(false);
                                setSecretNumber(null);
                              }}
                              onClick={() => {
                                selectOperator(operator);
                              }}
                              style={{ width: 260 }}
                            >
                              <Box flexDirection="column">
                                <div>
                                  <img
                                    src={`${config.blaiseApiUrl}${operator.logo_path}`}
                                    style={{ width: 30, paddingRight: 10 }}
                                    alt={`${operator.transit_agency_name} Logo`}
                                  ></img>
                                  {operator.transit_agency_name}
                                </div>
                                <div style={{ fontSize: 10, color: colors.blaiseGray }}>
                                  {operator.is_active === 1 ? '' : t('tripReq.comingSoon')}
                                </div>
                              </Box>
                            </ListItem>
                          );
                        })}
                      </List>
                    </>
                  )}
                </Button>
              </>
            )}
            {!operatorList && !isLoadingOperatorList && (
              <>
                <div style={{ fontSize: 12, color: colors.blaiseGray }}>
                  {t('operatorSelection.no_ta_message')}
                </div>
                <Button
                  style={{
                    backgroundColor: colors.blaiseGreen,
                    color: 'white',
                    borderRadius: 5,
                    padding: '5px 12px',
                    margin: '10px 0',
                    fontWeight: 'bold',
                    textTransform: 'initial'
                  }}
                  onClick={() => location.reload()}
                >
                  {t('operatorSelection.try_again')}
                </Button>
              </>
            )}
            {!operatorList && isLoadingOperatorList && (
              <div style={{ marginTop: 12 }}>
                <CircularProgress aria-label="Progress Icon" aria-busy="true" />
              </div>
            )}
          </Box>
          <hr />
          <Grid className="linksGrid">
            <SideBarItem
              label={t('navbar.request_a_ride')}
              route={ROUTES.home}
              icon={<FontAwesomeIcon icon={faBus} color={ICON_COLOR} size={ICON_SIZE} fixedWidth />}
              setIsMobileDrawerOpen={setIsMobileDrawerOpen}
            />
            <SideBarItem
              label={t('navbar.trips')}
              route={ROUTES.trips}
              icon={
                <FontAwesomeIcon icon={faRoute} color={ICON_COLOR} size={ICON_SIZE} fixedWidth />
              }
              setIsMobileDrawerOpen={setIsMobileDrawerOpen}
            />
            <SideBarItem
              label={t('navbar.historicaltrips')}
              route={ROUTES.history}
              icon={
                <FontAwesomeIcon icon={faClock} color={ICON_COLOR} size={ICON_SIZE} fixedWidth />
              }
              setIsMobileDrawerOpen={setIsMobileDrawerOpen}
            />
            <SideBarItem
              label={t('navbar.wallet')}
              route={ROUTES.wallet}
              icon={
                <FontAwesomeIcon icon={faWallet} color={ICON_COLOR} size={ICON_SIZE} fixedWidth />
              }
              setIsMobileDrawerOpen={setIsMobileDrawerOpen}
            />
            {operatorSelected && (
              <SideBarItem
                label={t('navbar.profile')}
                route={ROUTES.profile}
                icon={
                  <FontAwesomeIcon icon={faUser} color={ICON_COLOR} size={ICON_SIZE} fixedWidth />
                }
                setIsMobileDrawerOpen={setIsMobileDrawerOpen}
              />
            )}
          </Grid>
          <Box style={{ marginTop: '100px' }}>
            <button className="logoutBtn" onClick={handleLogout}>
              <span style={{ marginLeft: '0.5rem' }}>{t('navbar.signout')}</span>
              <FontAwesomeIcon icon={faSignOutAlt} color={ICON_COLOR} size={ICON_SIZE} />
            </button>
            <hr style={{ marginTop: '20px' }} />
            <Accordion>
              <AccordionSummary
                ref={helpRef}
                className="accordion-title"
                expandIcon={<FontAwesomeIcon icon={faChevronDown} color={colors.black} size="xs" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{t('navbar.help')}</Typography>
              </AccordionSummary>
              <Box>
                <div className="otherLinks">
                  <a href={'mailto:support@blaisetransit.com?subject=[BUG]'}>
                    {t('navbar.reportBug')}
                  </a>
                </div>
                {operatorSupportLink && (
                  <div className="otherLinks">
                    <a href={operatorSupportLink} target="_blank" rel="noopener noreferrer">
                      {t('navbar.faq')}
                    </a>
                  </div>
                )}
                {user && operatorSelected && (
                  <div className="otherLinks">
                    <a
                      href={`https://tally.so/r/mVdDJw?app=transit&email=${user?.email}&agency=${operatorSelected?.transit_agency_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('navbar.feedback')}
                    </a>
                  </div>
                )}
              </Box>
            </Accordion>
            <Accordion>
              <AccordionSummary
                ref={aboutUsRef}
                className="accordion-title"
                expandIcon={<FontAwesomeIcon icon={faChevronDown} color={colors.black} size="xs" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{t('navbar.about_us')}</Typography>
              </AccordionSummary>
              <Box>
                <div className="otherLinks">
                  <a
                    href={`https://www.blaisetransit.com/${t('navBarRedirect.privacy')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('navbar.privacypolicy')}
                  </a>
                </div>
                <div className="otherLinks">
                  <a
                    href={`https://www.blaisetransit.com/${t('navBarRedirect.terms')}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('navbar.terms')}
                  </a>
                </div>
              </Box>
            </Accordion>

            <Grid className="linksGrid">
              {config.version && (
                <div className="appVersion">
                  <span>{config.version}</span>
                </div>
              )}
            </Grid>
          </Box>
        </Box>

        <Box>
          <BrowserView>
            <Link to={ROUTES.downloadTheApp} style={{ textDecoration: 'none' }}>
              <Box className="download-the-app-wrapper">
                <Box className="app-icon-wrapper">
                  <FontAwesomeIcon icon={faMobileAlt} color={colors.blaiseGreen} size="2x" />
                </Box>
                <Typography className="download-the-app-text">
                  {t('navbar.download_the_app')}
                </Typography>
              </Box>
            </Link>
          </BrowserView>
          <MobileView>
            {isIOS ? (
              <a
                href={`https://apps.apple.com/ca/app/blaise-transit/id1502727307`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ width: '240px' }} src={AppStoreImg} alt="App Store badge" />
              </a>
            ) : (
              <a
                href={`https://play.google.com/store/apps/details?id=com.passengerappnative`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img style={{ width: '240px' }} src={GooglePlayImg} alt="Play Store badge" />
              </a>
            )}
          </MobileView>
        </Box>
      </MountAnimation>
    </>
  );
};

export default ExpandedSidebar;
