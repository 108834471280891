// even though we dont use all of these in the web appp right now, for right now I'm jsut going to keep all of these for reference!

export const mapStateToProps = (state) => {
  return {
    locationEnabled: state.locationEnabled,
    loading: state.loading,
    profilePicture: state.profilePicture,
    ride: state.ride,
    rideHasStarted: state.rideHasStarted,
    notificationType: state.notificationType,
    reloadPendingTrips: state.reloadPendingTrips,
    reloadUpcomingTrips: state.reloadUpcomingTrips,
    operatorSelected: state.operatorSelected,
    originAddress: state.originAddress,
    originCoords: state.originCoords,
    destAddress: state.destAddress,
    destCoords: state.destCoords,
    arrivePriority: state.arrivePriority,
    arrivePriorityTime: state.arrivePriorityTime,
    passengers: state.passengers,
    sidebarOpen: state.sidebarOpen,
    currentLocation: state.currentLocation,
    taGeoJSON: state.taGeoJSON,
    busStopsForTa: state.busStopsForTa,
    mapCentre: state.mapCentre,
    paymentMethod: state.paymentMethod,
    travelPreference: state.travelPreference,
    priceObject: state.priceObject
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    setLocationEnabled: (state) => dispatch({ type: 'SET_LOCATION_ENABLED', payload: state }),
    setLoading: (state) => dispatch({ type: 'SET_LOADING', payload: state }),
    setProfilePicture: (state) => dispatch({ type: 'SET_PROFILE_PICTURE', payload: state }),
    setRide: (state) => {
      dispatch({ type: 'SET_RIDE', payload: state });
    },
    setRideHasStarted: (state) => dispatch({ type: 'SET_RIDE_HAS_STARTED', payload: state }),
    setNotificationType: (state) => dispatch({ type: 'SET_NOTIFICATION_TYPE', payload: state }),
    setReloadPendingTrips: (trips) =>
      dispatch({ type: 'SET_RELOAD_PENDING_TRIPS', payload: trips }),
    setReloadUpcomingTrips: (trips) =>
      dispatch({ type: 'SET_RELOAD_UPCOMING_TRIPS', payload: trips }),
    setOperatorSelected: (state) => dispatch({ type: 'SET_OPERATOR_SELECTED', payload: state }),
    setOriginAddress: (state) => dispatch({ type: 'SET_ORIGIN_ADDRESS', payload: state }),
    setOriginCoords: (state) => dispatch({ type: 'SET_ORIGIN_COORDS', payload: state }),
    setDestAddress: (state) => dispatch({ type: 'SET_DEST_ADDRESS', payload: state }),
    setDestCoords: (state) => dispatch({ type: 'SET_DEST_COORDS', payload: state }),
    setArrivePriority: (state) => dispatch({ type: 'SET_PRIORITY', payload: state }),
    setArrivePriorityTime: (state) => dispatch({ type: 'SET_TIME', payload: state }),
    setPassengers: (state) => dispatch({ type: 'SET_PASSENGERS', payload: state }),
    setSidebarOpen: (state) => dispatch({ type: 'SET_SIDEBAR_OPEN', payload: state }),
    setCurrentLocation: (state) => dispatch({ type: 'SET_CURRENT_LOCATION', payload: state }),
    setTaGeoJSON: (state) => dispatch({ type: 'SET_TA_GEO_JSON', payload: state }),
    setBusStopsForTa: (state) => dispatch({ type: 'SET_BUS_STOPS_FOR_TA', payload: state }),
    setMapCentre: (state) => dispatch({ type: 'SET_MAP_CENTRE', payload: state }),
    setPaymentMethod: (state) => dispatch({ type: 'SET_PAYMENT_METHOD', payload: state }),
    setTravelPreference: (state) => dispatch({ type: 'SET_TRAVEL_PREFERENCE', payload: state }),
    setPriceObject: (state) => dispatch({ type: 'SET_PRICE_OBJECT', payload: state })
  };
};
