/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { isMobile } from 'react-device-detect';
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import config from '../../config';
import './PassengerProfile.scss';
import { mapDispatchToProps, mapStateToProps } from '../../utils/redux/Mapping';
import { connect } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import imageOfJustin from '../../assets/images/justin.jpeg';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import colors from '../../assets/sass/colors';
import { AccessTokenContext } from '../../components/App';

const LANGUAGE = navigator.language.substring(0, 2);

const PassengerProfile = ({ operatorSelected, t }) => {
  const [passenger, setPassenger] = useState(null);
  const [initialPassenger, setInitialPassenger] = useState({
    firstName: null,
    lastName: null,
    phoneNumber: null,
    hasDisability: null
  });
  const [initialPassengerType, setInitialPassengerType] = useState(null);
  const [newPassengerType, setNewPassengerType] = useState(null);
  const [taPassengerTypes, setTaPassengerTypes] = useState(null);
  const { user, logout } = useAuth0();
  const accessToken = useContext(AccessTokenContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });

  const handleChange = (event) => {
    const { id, value } = event.target;
    setPassenger((prevState) => {
      return {
        ...prevState,
        [id]: id === 'hasDisability' ? !passenger.hasDisability : value
      };
    });
  };

  const isDifferent = (
    initialPassenger,
    updatedPassenger,
    initialPassengerType,
    newPassengerType
  ) => {
    return !(
      initialPassenger.firstName === updatedPassenger.firstName &&
      initialPassenger.lastName === updatedPassenger.lastName &&
      initialPassenger.phoneNumber === updatedPassenger.phoneNumber &&
      initialPassenger.hasDisability === updatedPassenger.hasDisability &&
      initialPassengerType.id === newPassengerType.id
    );
  };

  const handleSave = async (event) => {
    try {
      if (!isDifferent(initialPassenger, passenger, initialPassengerType, newPassengerType))
        throw 'No changes were made, nothing to update.';

      const transitAgencyId = operatorSelected.transit_agency_id;

      let reqBody = {
        first_name: passenger.firstName,
        last_name: passenger.lastName,
        phone: passenger.phoneNumber,
        hasDisability: passenger.hasDisability
      };

      let updatePassengerTypeBody = {
        passenger_type_id: newPassengerType.id
      };

      axios
        .put(`${config.blaiseApiUrl}/passengers/${user.sub}`, reqBody, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(() => {
          axios
            .put(
              `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transitAgencyId}/passengerType`,
              updatePassengerTypeBody,
              { headers: { Authorization: `Bearer ${accessToken}` } }
            )
            .then(() => {
              setInitialPassenger(passenger);
              setInitialPassengerType(newPassengerType);
              openSnackbar(t('profile.changesSaved'), colors.blaiseGreen);
            })
            .catch(() => {
              openSnackbar(t('profile.changesNotSaved'), colors.red);
            });
        })
        .catch(() => {
          openSnackbar(t('profile.changesNotSaved'), colors.red);
        });
    } catch (err) {
      console.log(err);
    }

    event.preventDefault();
  };

  const handleDelete = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (confirm(t('profile.deleteAcctCheck'))) {
      axios
        .delete(`${config.blaiseApiUrl}/passengers/${user.sub}?auth0=true`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .catch(() => {
          openSnackbar(t('profile.deleteAcctError'), colors.red);
        });

      logout();
    }
  };

  const handlePassengerTypeChange = (event) => {
    const selectedPassengerType = taPassengerTypes.find(
      (element) => element.id === event.target.value
    );
    if (selectedPassengerType) {
      setNewPassengerType(selectedPassengerType);
    }
  };

  useEffect(() => {
    const transitAgencyId = operatorSelected.transit_agency_id;

    (async () => {
      const passenger = (
        await axios.get(`${config.blaiseApiUrl}/passengers/${user.sub}`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      setInitialPassenger(passenger);
      setPassenger(passenger);
    })();

    (async () => {
      const initialPassengerType = (
        await axios.get(
          `${config.blaiseApiUrl}/passengers/${user.sub}/transitagency/${transitAgencyId}/passengerType`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      setInitialPassengerType(initialPassengerType);
      setNewPassengerType(initialPassengerType);
    })();

    (async () => {
      const taPassengerTypes = (
        await axios.get(
          `${config.blaiseApiUrl}/transitagencies/${transitAgencyId}/passengerTypes`,
          { headers: { Authorization: `Bearer ${accessToken}` } }
        )
      ).data;

      setTaPassengerTypes(taPassengerTypes);
    })();
  }, []);

  let avatar;

  if (config.isDemo) {
    avatar = (
      <Avatar
        src={imageOfJustin}
        alt={t('altText.profile_avatar')}
        className="profile-picture"
      ></Avatar>
    );
  } else {
    avatar = (
      <Avatar
        src={user?.picture}
        alt={t('altText.profile_avatar')}
        className="profile-picture"
      ></Avatar>
    );
  }

  const labelPropsStyle = { shrink: true, style: { fontWeight: 'bold' } };

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };
  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <Box display="flex" flexDirection="column" alignItems="center">
        {passenger === null && taPassengerTypes === null && initialPassengerType === null && (
          <div className="blaise-progress-top">
            <CircularProgress aria-label="Progress Icon" aria-busy="true" />
          </div>
        )}
        {passenger && (
          <form onSubmit={handleSave}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {avatar}
            </Box>
            <fieldset>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{
                  height: `${
                    isMobile && `calc(${window.innerHeight}px - 48px - 190px - 46px - 46px)`
                  }`, // 48px for navbar, 190px for avatar, 46px for bottom buttons.
                  overflow: 'auto'
                }}
              >
                <div className="form-group">
                  <TextField
                    label={t('profile.firstName')}
                    id="firstName"
                    type="text"
                    value={passenger.firstName || ' '}
                    onChange={handleChange}
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label={t('profile.lastName')}
                    id="lastName"
                    type="text"
                    value={passenger.lastName || ' '}
                    onChange={handleChange}
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label={t('profile.phoneNumber')}
                    id="phoneNumber"
                    type="tel"
                    value={passenger.phoneNumber || ' '}
                    onChange={handleChange}
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    label={t('profile.email')}
                    id="email"
                    type="text"
                    value={passenger.email}
                    onChange={handleChange}
                    disabled
                    InputLabelProps={labelPropsStyle}
                  />
                </div>
                <div style={{ width: '100%' }}>
                  <InputLabel
                    id={t('passenger.select_passenger_type')}
                    style={{ fontSize: 15, fontWeight: 'bold', maxWidth: 'max-content' }}
                  >
                    {t('passenger.passenger_type')}
                  </InputLabel>
                  <div style={{ maxWidth: 'max-content' }}>
                    {newPassengerType && (
                      <Select
                        onChange={handlePassengerTypeChange}
                        value={newPassengerType.id}
                        labelId={t('passenger.select_passenger_type')}
                        id="Select"
                      >
                        {taPassengerTypes &&
                          taPassengerTypes.map((element) => {
                            return (
                              <MenuItem key={element.id} value={element.id}>
                                {LANGUAGE === 'en' ? element.english : element.french}
                              </MenuItem>
                            );
                          })}
                      </Select>
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={passenger.hasDisability}
                        onChange={handleChange}
                        name="hasDisability"
                        id="hasDisability"
                      />
                    }
                    label={t('profile.reducedMobility')}
                  />
                </div>
              </Box>
              <Box
                my={1}
                display="flex"
                flexDirection="row"
                justifyContent="center"
                flexWrap="wrap"
              >
                <Button type="submit" color="primary" variant="contained">
                  {t('profile.save')}
                </Button>
                <Button type="button" color="secondary" variant="contained" onClick={handleDelete}>
                  {t('profile.deleteAcct')}
                </Button>
              </Box>
              <br />
            </fieldset>
          </form>
        )}
      </Box>
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(PassengerProfile));
