import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import tripAsset from '../../assets/images/trip-details.svg';
import './Destinations.scss';
import moment from 'moment';
import config from '../../config';
import { withTranslation } from 'react-i18next';

const Destinations = ({ data, t, type }) => {
  const [origin, setOrigin] = useState('');
  const [destination, setDestination] = useState('');

  const locationFormatter = (location) => {
    if (location) {
      return (
        <Box className="location" flexGrow={1}>
          {location}
        </Box>
      );
    } else {
      return (
        <Box className="locationPending" flexGrow={1}>
          {t('tripDetails.pending')}
        </Box>
      );
    }
  };

  const timeFormatter = (timeString) => {
    if (timeString) {
      const time = moment(timeString).format('HH:mm');
      return time;
    } else {
      return t('tripDetails.timePending');
    }
  };

  useEffect(() => {
    // all types should have origin name, if it doesn't then use here api
    if (data.origin_name) {
      setOrigin(data.origin_name);
      setDestination(data.dest_name);
    } else {
      (async () => {
        //TODO: change return language dependent on user selected language
        await axios
          .get(
            `${config.mapboxReverseGeoUrl}/${data.origin_lon},${data.origin_lat}.json?access_token=${config.mapboxToken}&limit=1`
          )
          .then((response) => {
            setOrigin(response.data.features[0].place_name);
          })
          .catch((err) => {
            setOrigin(data.origin_lat + ', ' + data.origin_lon);
            console.log(err);
          });
      })();

      (async () => {
        await axios
          .get(
            `${config.mapboxReverseGeoUrl}/${data.dest_lon},${data.dest_lat}.json?access_token=${config.mapboxToken}&limit=1`
          )
          .then((response) => {
            setDestination(response.data.features[0].place_name);
          })
          .catch((err) => {
            setDestination(data.dest_lat + ', ' + data.dest_lon);
            console.log(err);
          });
      })();
    }
  }, []);

  return (
    <>
      {type === 'upcoming' && (
        <Box display="flex" flexDirection="row">
          {t('comingTrips.busname')}
          {data.bus_name ? data.bus_name : t('viewTrips.pendingTrips')}
        </Box>
      )}
      <Box display="flex" flexDirection="row" justifyItems="flex-start" ml={1} mr={2}>
        <Box>
          <img src={tripAsset} id="tripAsset" alt={t('altText.trip_details_icon')} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          mt={0.5}
          mb={0.75}
          flexGrow={1}
        >
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {origin ? (
              <Box className="location" flexGrow={1}>
                {origin}
              </Box>
            ) : (
              <Box className="loading" flexGrow={1}>
                {t('tripDetails.loading')}
              </Box>
            )}
            {/* rejected trips use earliest_departure and latest_arrival*/}
            <Box className="time">
              {timeFormatter(
                data.earliest_departure ? data['earliest_departure'] : data['departure_time']
              )}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {locationFormatter(data['pickup_name'])}
            <Box className="time">{timeFormatter(data['pickup_time'])}</Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {locationFormatter(data['dropoff_name'])}
            <Box className="time">{timeFormatter(data['dropoff_time'])}</Box>
          </Box>
          <Box display="flex" flexDirection="row" justifyContent="space-between">
            {origin ? (
              <Box className="location" flexGrow={1}>
                {destination}
              </Box>
            ) : (
              <Box className="loading" flexGrow={1}>
                {t('tripDetails.loading')}
              </Box>
            )}
            <Box className="time">
              {timeFormatter(data.latest_arrival ? data['latest_arrival'] : data['arrival_time'])}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withTranslation('common')(withRouter(Destinations));
