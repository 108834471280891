import React, { useState } from 'react';
import ReactMapboxGl, { Marker, Layer, Feature, GeoJSONLayer } from 'react-mapbox-gl';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import colors from '../../assets/sass/colors';
import './HomeMap.scss';
import TripRequest from '../../components/TripRequest/TripRequest';
import * as turf from '@turf/turf';
import { mapDispatchToProps, mapStateToProps } from '../../utils/redux/Mapping';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import busStopIcon from '../../assets/images/bus-stop.svg';
import { isMobile } from 'react-device-detect';

const Map = ReactMapboxGl({
  accessToken: config.mapboxToken
});

const image = new Image(12, 12);
image.src = busStopIcon;
const images = ['myImage', image];

const HomeMap = ({
  busStopsForTa,
  taGeoJSON,
  setMapCentre,
  mapCentre,
  originCoords,
  setOriginCoords,
  destCoords,
  setDestCoords
}) => {
  const [routeLine, setRouteLine] = useState(null);

  const setMapOriginCoordinates = (lat, lng) => {
    let coords = null;

    if (destCoords) {
      coords = turf.lineString([
        [lng, lat],
        [destCoords.lng, destCoords.lat]
      ]).geometry.coordinates;
    }
    setOriginCoords({ lat: lat, lng: lng });
    setMapCentre({ lat, lng });
    setRouteLine(coords);
  };

  const setMapDestinationCoordinates = (lat, lng) => {
    let coords = null;

    if (originCoords) {
      coords = turf.lineString([
        [originCoords.lng, originCoords.lat],
        [lng, lat]
      ]).geometry.coordinates;
    }

    setDestCoords({ lat: lat, lng: lng });
    setRouteLine(coords);
  };

  const linePaint = (ReactMapboxGl.LinePaint = {
    'line-color': colors.blaiseGray,
    'line-width': 2,
    'line-dasharray': [0, 2]
  });

  const lineLayout = {
    'line-cap': 'round'
  };

  return (
    <Box style={{ position: 'relative' }}>
      {mapCentre && (
        <Map
          tabindex="0"
          id="map"
          role="application"
          aria-label="Map"
          style={'mapbox://styles/mapbox/light-v10'}
          zoom={[12]}
          className="map"
          center={[mapCentre.lng, mapCentre.lat]}
          containerStyle={{
            height: `${isMobile ? `calc(${window.innerHeight}px - 48px - 318.5px)` : '100vh'}`, // 48px for navbar, 318.5px for stepper container.
            width: '100vw'
          }}
        >
          {busStopsForTa && (
            <Layer
              type="symbol"
              id="marker"
              layout={{ 'icon-image': 'myImage', 'icon-allow-overlap': true }}
              images={images}
              minZoom={11}
            >
              {busStopsForTa.busStops.map((busStop) => {
                const stopCoords = [Number(busStop.coord[0]), Number(busStop.coord[1])];
                return <Feature key={busStop.id} coordinates={stopCoords} />;
              })}
            </Layer>
          )}

          {originCoords && (
            <Marker coordinates={originCoords} anchor="bottom">
              <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaisePurple} size="2x" />
            </Marker>
          )}
          {destCoords && (
            <Marker coordinates={destCoords} anchor="bottom">
              <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaiseRed} size="2x" />
            </Marker>
          )}

          {routeLine && (
            <Layer type="line" paint={linePaint} layout={lineLayout}>
              <Feature coordinates={routeLine} />
            </Layer>
          )}
          {taGeoJSON && (
            <GeoJSONLayer
              data={taGeoJSON}
              fillPaint={{
                'fill-color': colors.blaiseGreen,
                'fill-opacity': 0.2
              }}
            ></GeoJSONLayer>
          )}
        </Map>
      )}
      <TripRequest
        setMapOriginCoordinates={setMapOriginCoordinates}
        setMapDestinationCoordinates={setMapDestinationCoordinates}
      />
    </Box>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('common')(withRouter(HomeMap)));
