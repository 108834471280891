import React, { useContext, useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { format } from 'date-fns';

import { Box, Button, Modal, Typography } from '@material-ui/core';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faBan, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../assets/sass/colors';

import { PaymentType } from '../../enums/PaymentType';
import PaymentMethodItem from '../PaymentMethodItem/PaymentMethodItem';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import Popup from '../Dialog/Popup';
import { AccessTokenContext } from '../App';

const CARD_OPTIONS = {
  style: {
    base: {
      iconColor: colors.blaiseGray,
      color: colors.black,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883'
      },
      '::placeholder': {
        color: colors.blaiseGray
      }
    },
    invalid: {
      iconColor: colors.red,
      color: colors.red
    }
  }
};

const PurchasePassModal = ({
  open,
  currentPaymentMethods,
  handleClose,
  retrievePassengerPass,
  setError,
  t,
  transitPasses,
  viewFrom
}) => {
  const accessToken = useContext(AccessTokenContext);
  const selectedOperator = useSelector((state) => state.operatorSelected);
  const { user } = useAuth0();
  const stripe = useStripe();
  const elements = useElements();

  const [selectedPass, setSelectedPass] = useState(transitPasses[0]?.price_stripe_id);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [openOneTimePayment, setOpenOneTimePayment] = useState(false);
  const [oneTimePaymentDetails, setOneTimePaymentDetails] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });

  const passStart = format(new Date(), 'LLLL dd');

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  const changePaymentMethod = (method) => {
    setOpenOneTimePayment(false);
    setOneTimePaymentDetails(null);
    setSelectedPayment(method);
  };

  const getPayload = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement)
      });

      if (!error) {
        setOneTimePaymentDetails(paymentMethod.id);
        setOpenOneTimePayment(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePurchaseSubmit = () => {
    if (
      !selectedPayment ||
      (selectedPayment === PaymentType.ONE_TIME_PAYMENT && !oneTimePaymentDetails)
    ) {
      return openSnackbar(t('payment.payment_details_missing'), colors.red);
    }
    // show confirmation dialog with single passenger warning when purchasing through trip request page
    if (!viewFrom) {
      handlePurchasePass();
    } else {
      setOpenPopup(true);
    }
  };

  const handlePurchasePass = async () => {
    try {
      const reqBody = {
        passengerId: user.sub,
        priceId: selectedPass,
        paymentMethodId:
          selectedPayment === PaymentType.ONE_TIME_PAYMENT
            ? oneTimePaymentDetails
            : selectedPayment.id,
        isOneTime: selectedPayment === PaymentType.ONE_TIME_PAYMENT && oneTimePaymentDetails,
        transitAgencyId: selectedOperator.transit_agency_id,
        isConnectedAccount: true
      };

      await axios.post(`${config.blaiseApiUrl}/subscribe`, reqBody, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      retrievePassengerPass();
      setOpenPopup(false);
      openSnackbar(t('labels_reusable.success'), colors.blaiseGreen);
      handleClose(false);
    } catch (error) {
      console.log(error);
      openSnackbar(t('console.genericError'), colors.red);
    }
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      {openPopup && (
        <Popup
          open={openPopup}
          handleClose={() => {
            setOpenPopup(false);
            handleClose(false);
          }}
          confirmButtonText={t('labels_reusable.confirm')}
          confirmAction={handlePurchasePass}
          title={t('labels_reusable.confirmation')}
          text={t('transitPasses.single_pass_request_warning')}
        />
      )}
      <Modal open={open} onClose={() => handleClose(false)}>
        <Box className="paper" display="flex" flexDirection="column" style={styles.modalContainer}>
          <Typography variant="h6">{t('transitPasses.purchase_pass')}</Typography>
          {transitPasses.map((pass) => {
            let passDuration = 0;
            if (pass.interval === 'month') {
              passDuration = Number(pass.interval_count) * 30;
            }

            // custom plans will have an extra day so that the pass can expire at the end of the day vs. the hour the pass was purchased
            // eg. a 30 day plan is actually allowed 30 days + extra hours
            if (pass.interval === 'day') {
              passDuration = Number(pass.interval_count) * 1 - 1;
            }

            const date = new Date();
            date.setDate(date.getDate() + Number(passDuration)); // Set now + 30 days as the end date
            const passEnd = format(date, 'LLLL dd');

            return (
              <Button
                key={pass.price_stripe_id}
                style={
                  selectedPass === pass.price_stripe_id
                    ? styles.selectedPassContainer
                    : styles.passContainer
                }
                onClick={() => setSelectedPass(pass.price_stripe_id)}
              >
                <Box display="flex" flexDirection="column">
                  <Typography variant="body1" style={styles.termsSubtitleStyle}>
                    {t('transitPasses.unlimited_rides_in_days', {
                      passDuration
                    })}
                  </Typography>
                  <Typography style={styles.passPriceText}>
                    ${(pass.unit_amount / 100).toFixed(2)} / {t('transitPasses.pass')}
                  </Typography>

                  <Box style={styles.termsContainerStyle}>
                    <Box style={styles.iconContainerStyle}>
                      <FontAwesomeIcon color={colors.black} icon={faClock} size="lg" />
                    </Box>
                    <Typography style={styles.termsTextStyle}>
                      {t('transitPasses.pass_terms_validation_period')}
                    </Typography>
                  </Box>
                  <Box style={styles.termsContainerStyle}>
                    <Box style={styles.iconContainerStyle}>
                      <FontAwesomeIcon color={colors.black} icon={faUser} size="lg" />
                    </Box>
                    <Typography style={styles.termsTextStyle}>
                      {t('transitPasses.pass_terms_single_pass_trip')}
                    </Typography>
                  </Box>
                  <Box style={styles.termsContainerStyle}>
                    <Box style={styles.iconContainerStyle}>
                      <FontAwesomeIcon color={colors.black} icon={faBan} size="lg" />
                    </Box>
                    <Typography style={styles.termsTextStyle}>
                      {t('transitPasses.pass_terms_no_cancellation')}
                    </Typography>
                  </Box>

                  {selectedPass === pass.price_stripe_id && passStart && passEnd && (
                    <Typography style={styles.passValidText}>
                      {t('transitPasses.pass_validation_range', {
                        passStart,
                        passEnd
                      })}
                    </Typography>
                    // </Box>
                  )}
                </Box>
              </Button>
            );
          })}
          <Typography>{t('payment.select_payment_method')}</Typography>
          <>
            {currentPaymentMethods.map((card) => {
              return (
                <PaymentMethodItem
                  key={card.id}
                  paymentItem={{ payment_type: PaymentType.CARD }}
                  isSelected={card.id === selectedPayment?.id}
                  setSelectedPayment={changePaymentMethod}
                  text={`${card.card.brand.charAt(0).toUpperCase() + card.card.brand.slice(1)} ${t(
                    'payment.card_ending_with'
                  )} **** ${card.card.last4}`}
                  cardDetails={card}
                />
              );
            })}
            <PaymentMethodItem
              key={PaymentType.ONE_TIME_PAYMENT}
              paymentItem={{ payment_type: PaymentType.ONE_TIME_PAYMENT }}
              isSelected={selectedPayment === PaymentType.ONE_TIME_PAYMENT}
              setSelectedPayment={() => {
                setSelectedPayment(PaymentType.ONE_TIME_PAYMENT);
                setOpenOneTimePayment(true);
              }}
              text={t('payment.one_time_payment')}
            />
            {openOneTimePayment && (
              <>
                <Box my={2} p={2} style={styles.oneTimePaymentContainer}>
                  <CardElement
                    options={CARD_OPTIONS}
                    onChange={(e) => {
                      setError(e.error);
                    }}
                  />
                </Box>
                <Box display="flex" justifyContent="center">
                  <Button color="primary" variant="contained" onClick={getPayload}>
                    {t('labels_reusable.save')}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setOpenOneTimePayment(false);
                      setSelectedPayment(null);
                    }}
                    // theme styling not showing when opening modal from trip request
                    style={styles.cancelButton}
                  >
                    {t('labels_reusable.cancel')}
                  </Button>
                </Box>
              </>
            )}
          </>
          {!openOneTimePayment && (
            <Button variant="contained" color="primary" onClick={handlePurchaseSubmit}>
              {t('payment.purchase')}
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default withTranslation('common')(PurchasePassModal);

const styles = {
  modalContainer: {
    overflowY: 'auto',
    maxHeight: '90%'
  },
  cancelButton: {
    marginLeft: '10px',
    backgroundColor: colors.red
  },
  oneTimePaymentContainer: {
    border: `2px solid ${colors.blaiseLightGreen}`,
    borderRadius: 3,
    backgroundColor: colors.blaiseLightGreen
  },
  passContainer: {
    border: `2px solid ${colors.blaiseLightGray}`,
    borderRadius: 3,
    margin: 20,
    padding: 20
  },
  passPriceText: {
    textAlign: 'left',
    paddingBottom: '1em'
  },
  selectedPassContainer: {
    backgroundColor: colors.blaiseLightGreen,
    borderRadius: 3,
    margin: 15,
    padding: 20
  },
  termsSubtitleStyle: {
    textAlign: 'left',
    fontWeight: 'bold'
  },
  termsContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 5
  },
  termsTextStyle: {
    paddingLeft: 10
  },
  iconContainerStyle: {
    width: '2em'
  },
  passValidContainer: {
    borderRadius: 5,
    height: 70,
    display: 'flex',
    alignItems: 'center',
    padding: '0.5em'
  },
  passValidText: {
    fontStyle: 'italic',
    fontWeight: 'bold',
    padding: '0.5em'
  }
};
