import { TravelPreferenceType } from '../../enums/TravelPreferenceType';

// even though we dont use all of these in the web appp right now, for right now I'm jsut going to keep all of these for reference!
export const initialState = {
  locationEnabled: false,
  loading: false, //A global loading state if any component requires it. Make sure to reset it back to false.
  profilePicture: null, //The URL for the profile picture
  ride: null,
  rideHasStarted: false,
  notificationType: null,
  reloadPendingTrips: false,
  reloadUpcomingTrips: false,
  operatorSelected: null,
  originAddress: null,
  originCoords: null,
  destAddress: null,
  destCoords: null,
  arrivePriority: null,
  arrivePriorityTime: null,
  passengers: null,
  sidebarOpen: true,
  currentLocation: null,
  taGeoJSON: null,
  busStopsForTa: null,
  mapCentre: { lat: -73.567531, lng: 45.506595 },
  paymentMethod: null,
  travelPreference: TravelPreferenceType.DEPART_AT,
  priceObject: {}
};

export const reducers = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOCATION_ENABLED':
      return {
        ...state,
        locationEnabled: action.payload
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'SET_PROFILE_PICTURE':
      return {
        ...state,
        profilePicture: action.payload
      };
    case 'SET_RIDE':
      return {
        ...state,
        ride: action.payload
      };
    case 'SET_RIDE_HAS_STARTED':
      return {
        ...state,
        rideHasStarted: action.payload
      };
    case 'SET_NOTIFICATION_TYPE':
      return {
        ...state,
        notificationType: action.payload
      };
    case 'SET_RELOAD_PENDING_TRIPS':
      return {
        ...state,
        reloadPendingTrips: action.payload
      };
    case 'SET_RELOAD_UPCOMING_TRIPS':
      return {
        ...state,
        reloadUpcomingTrips: action.payload
      };
    case 'SET_OPERATOR_SELECTED':
      return {
        ...state,
        operatorSelected: action.payload
      };
    case 'SET_ORIGIN_ADDRESS':
      return {
        ...state,
        originAddress: action.payload
      };
    case 'SET_ORIGIN_COORDS':
      return {
        ...state,
        originCoords: action.payload
      };
    case 'SET_DEST_ADDRESS':
      return {
        ...state,
        destAddress: action.payload
      };
    case 'SET_DEST_COORDS':
      return {
        ...state,
        destCoords: action.payload
      };
    case 'SET_PRIORITY':
      return {
        ...state,
        arrivePriority: action.payload
      };
    case 'SET_TIME':
      return {
        ...state,
        arrivePriorityTime: action.payload
      };
    case 'SET_PASSENGERS':
      return {
        ...state,
        passengers: action.payload
      };
    case 'SET_SIDEBAR_OPEN':
      return {
        ...state,
        sidebarOpen: action.payload
      };
    case 'SET_CURRENT_LOCATION':
      return {
        ...state,
        currentLocation: action.payload
      };
    case 'SET_TA_GEO_JSON':
      return {
        ...state,
        taGeoJSON: action.payload
      };
    case 'SET_BUS_STOPS_FOR_TA':
      return {
        ...state,
        busStopsForTa: action.payload
      };
    case 'SET_MAP_CENTRE':
      return {
        ...state,
        mapCentre: action.payload
      };
    case 'SET_PAYMENT_METHOD':
      return {
        ...state,
        paymentMethod: action.payload
      };
    case 'SET_TRAVEL_PREFERENCE':
      return {
        ...state,
        travelPreference: action.payload
      };
    case 'SET_PRICE_OBJECT':
      return {
        ...state,
        priceObject: action.payload
      };
    default:
      return state;
  }
};
