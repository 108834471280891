import React, { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { CircularProgress, Box } from '@material-ui/core';
import config from '../../config';
import { useHistory } from 'react-router';
import ROUTES from '../../utils/routes';
import { datetimeFormat } from '../../utils/dateTimeFormats';
import Destinations from '../Destinations/Destinations';
import { AccessTokenContext } from '../App';
const NoShowRides = ({ t, type }) => {
  const [rideList, setRideList] = useState([]);
  const [loading, setLoading] = useState(true);
  const accessToken = useContext(AccessTokenContext);

  const { user } = useAuth0();
  const history = useHistory();

  useEffect(() => {
    getRides();
  }, []);

  const getRides = async () => {
    const rides = (
      await axios.get(`${config.blaiseApiUrl}/passengers/${user.sub}/noShow`, {
        headers: { Authorization: `Bearer ${accessToken}` }
      })
    ).data;

    setRideList(rides);
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" style={{ width: '100%' }}>
      {loading && (
        <div>
          <CircularProgress />
        </div>
      )}

      {rideList.length === 0 && !loading && (
        <Box flexDirection="column" alignItems="center" display="flex" m={2}>
          <p>{t('viewTrips.noTrips')}</p>
        </Box>
      )}

      {!loading &&
        rideList.map((ride) => (
          <div
            key={`key${ride.ride_proposal_id}`}
            onClick={() =>
              history.push(`${ROUTES.tripDetails}?rideID=${ride.ride_proposal_id}&type=noShow`)
            }
            onKeyDown={() =>
              history.push(`${ROUTES.tripDetails}?rideID=${ride.ride_proposal_id}&type=noShow`)
            }
            role="button"
            tabIndex="0"
            className="tripContainer"
          >
            <Box display="flex" justifyContent="space-between" mx={2}>
              <div style={{ fontWeight: 'bold' }}>
                {datetimeFormat(new Date(ride.departure_time))}
              </div>
              <div style={{ fontWeight: 'bold' }}>
                {`${Math.round(
                  (new Date(ride.arrival_time) - new Date(ride.departure_time)) / 60000
                )} minutes`}
              </div>
            </Box>

            <Destinations data={ride} type={type} />

            {ride.payment_type && typeof ride.trip_price === 'number' && (
              <Box>
                <Box mx={2}>
                  <span style={{ fontWeight: 'bold' }}>{t('profile.pay')}: </span>
                  <span>{t(`payment.${ride.payment_type}`)}</span>
                </Box>

                <Box mx={2}>
                  <span style={{ fontWeight: 'bold' }}>{t('payment.total')}: </span>
                  <span>${(ride.trip_price / 100).toFixed(2)}</span>
                </Box>
              </Box>
            )}
          </div>
        ))}
    </Box>
  );
};

export default withTranslation('common')(NoShowRides);
