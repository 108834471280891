import React, { useContext, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import { useAuth0 } from '@auth0/auth0-react';
import colors from '../../assets/sass/colors';
import TextField from '@material-ui/core/TextField';
import config from '../../config';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import CustomSnackbar from '../Snackbar/CustomSnackbar';
import { AccessTokenContext } from '../App';

const AddPhysicalTicketModal = (props) => {
  const [ticket, setTicket] = useState('');
  const accessToken = useContext(AccessTokenContext);
  const { user } = useAuth0();
  const taId = props.selectedOperator.transit_agency_id;
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });

  // submit ticket
  const handleSubmit = async () => {
    try {
      const reqBodyValidate = { ticket_number: ticket, taId };
      axios
        .post(`${config.blaiseApiUrl}/ticket/validate`, reqBodyValidate, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(async (response) => {
          if (response.data === 'AVAILABLE') {
            try {
              const reqBodyUse = { passenger_id: user.sub, ticket_number: ticket, taId };
              //call endpoint to use the ticket and update the ticket table
              await axios.put(`${config.blaiseApiUrl}/ticket/use`, reqBodyUse, {
                headers: { Authorization: `Bearer ${accessToken}` }
              });
              //call endpoint to update the passenger balance
              axios
                .put(
                  `${config.blaiseApiUrl}/passengers/${user.sub}/updatePassengerBalanceWTicket/${taId}`,
                  { ticket_number: ticket },
                  { headers: { Authorization: `Bearer ${accessToken}` } }
                )
                .then((response) => {
                  openSnackbar(props.t('payment.confirmation_ticket_msg'), colors.blaiseGreen);
                  props.setPassengerBalance(response.data.new_balance);
                  props.handleClose(false);
                });
            } catch (err) {
              console.log(err);
            }
          } else if (response.data === 'UNAVAILABLE') {
            openSnackbar(props.t('payment.ticket_is_used_msg'), colors.red);
          } else {
            openSnackbar(props.t('payment.ticket_not_existed_msg'), colors.red);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  };
  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  return (
    <>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <Modal
        open={props.open}
        onClose={() => props.handleClose(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="paper">
          <div className="ticket-modal-title">{props.t('payment.add_physical_ticket_number')}</div>
          <TextField
            id="standard-basic"
            name="ticket"
            label={'Ticket'}
            InputLabelProps={{ style: { color: colors.blaiseGreen, fontSize: 15 } }}
            onChange={(e) => {
              setTicket(e.target.value);
            }}
          />{' '}
          <br />
          <Button
            variant="contained"
            style={{ marginTop: '10px' }}
            color="primary"
            onClick={handleSubmit}
          >
            {props.t('payment.add_ticket')}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default withTranslation('common')(AddPhysicalTicketModal);
