import React from 'react';
import { Button, Typography, Box } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../assets/sass/colors';
import './AddressSearchResult.scss';
import { WhenAndWhereMobileView } from '../../../enums/whenAndWhereMobileViews';

const AddressSearchResult = ({
  address,
  searchType,
  setOriginAddress,
  setDestAddress,
  setAddressResults,
  updateMapCoords,
  setMobileView
}) => {
  const setAddress = () => {
    searchType === 'origin' ? setOriginAddress(address.mainText) : setDestAddress(address.mainText);
    setAddressResults([]);
    updateMapCoords(address, searchType);
    setMobileView && setMobileView(WhenAndWhereMobileView.DEFAULT);
  };

  return (
    <Button onClick={() => setAddress()} className="address-container">
      <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.black} size="lg" />
      <Box>
        <Typography className="address-text">{address.mainText}</Typography>
        <Typography className="address-secondary">{address.secondaryText}</Typography>
      </Box>
    </Button>
  );
};

export default AddressSearchResult;
