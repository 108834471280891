import React, { useContext, useEffect, useState } from 'react';
import ReactMapboxGl, { Marker, Layer, Feature } from 'react-mapbox-gl';
import { withTranslation } from 'react-i18next';
import { Box, Button } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import config from '../../config';
import colors from '../../assets/sass/colors';
import Firebase from 'firebase';
import busIcon from '../../assets/images/bus-icon.png';
import { mapDispatchToProps, mapStateToProps } from '../../utils/redux/Mapping';
import { connect } from 'react-redux';
import { slideInUp, slideOutDown } from 'react-animations';
import Radium, { StyleRoot } from 'radium';
import Destinations from '../../components/Destinations/Destinations';
import Typography from '@material-ui/core/Typography';
import CustomSnackbar from '../../components/Snackbar/CustomSnackbar';
import { useHistory } from 'react-router';
import ROUTES from '../../utils/routes';
import { AccessTokenContext } from '../../components/App';

const Map = ReactMapboxGl({
  accessToken: config.mapboxToken
});

const style = {
  slideInUp: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideInUp, 'slideInUp'),
    bottom: '0%',
    position: 'absolute',
    margin: 'auto',
    width: '100%',
    height: '70%',
    alignItems: 'center'
  },
  slideOutDown: {
    animation: 'x 1s',
    animationName: Radium.keyframes(slideOutDown, 'slideOutDown'),
    bottom: '0%',
    position: 'absolute',
    margin: 'auto',
    width: '100%',
    height: '70%',
    alignItems: 'center'
  },
  destStyle: {
    width: '80%',
    backgroundColor: 'white',
    opacity: '100%',
    borderRadius: 10,
    elevation: 4,
    boxShadow: '3px 3px 3px 3px #ccc',
    padding: 10,
    left: '10%',
    position: 'absolute'
  }
};

const TripProcess = ({ t, ride }) => {
  const [mapCentre, setMapCentre] = useState(null);
  const [userLocation, setUserLocation] = useState(null);
  const [route1, setRoute1] = useState(null);
  const [route2, setRoute2] = useState(null);
  const [busLocation, setBusLocation] = useState(null);
  const [busBearing, setBusBearing] = useState(0);
  const [lockedInRoute, setLockedInRoute] = useState(null);
  const [undeterminedRouteShape, setUndeterminedRouteShape] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [needsToAnimateOut, setNeedsToAnimateOut] = useState(false);
  const accessToken = useContext(AccessTokenContext);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: null
  });
  const history = useHistory();
  const originCoords = [Number(ride.origin_lon), Number(ride.origin_lat)];
  const pickupCoords = [Number(ride.pickup_lon), Number(ride.pickup_lat)];
  const dropoffCoords = [Number(ride.dropoff_lon), Number(ride.dropoff_lat)];
  const destCoords = [Number(ride.dest_lon), Number(ride.dest_lat)];

  const linePaint = (ReactMapboxGl.LinePaint = {
    'line-color': colors.blaiseGray,
    'line-width': 2,
    'line-dasharray': [0, 2]
  });

  const busLinePaint = (ReactMapboxGl.LinePaint = {
    'line-color': colors.blaiseGreen,
    'line-width': 2,
    'line-dasharray': [0, 2]
  });

  const lineLayout = {
    'line-cap': 'round'
  };

  const getWalkingLines = async () => {
    let r1Body = {
      p1: { lon: ride.origin_lon, lat: ride.origin_lat },
      p2: { lon: ride.pickup_lon, lat: ride.pickup_lat },
      walking: true
    };

    //get list of coordinates for this route. This can then be transformed into a route on a map
    try {
      const route = (
        await axios.post(`${config.blaiseApiUrl}/route`, r1Body, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      setRoute1(route);
    } catch (err) {
      console.log(err);
    }

    let r2Body = {
      p1: { lon: ride.dropoff_lon, lat: ride.dropoff_lat },
      p2: { lon: ride.dest_lon, lat: ride.dest_lat },
      walking: true
    };

    try {
      const route = (
        await axios.post(`${config.blaiseApiUrl}/route`, r2Body, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      setRoute2(route);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRouteEnd = async () => {
    const reqBody = {
      depDatetime: ride.departure_time,
      pickupDatetime: ride.pickup_time,
      dropoffDatetime: ride.dropoff_time,
      arrivalDatetime: ride.arrival_time,
      pstid: ride.pickup_stop_id,
      dstid: ride.dropoff_stop_id,
      olon: ride.origin_lon,
      olat: ride.origin_lat,
      dlon: ride.dest_lon,
      dlat: ride.dest_lat
    };

    try {
      await axios
        .post(`${config.blaiseApiUrl}/rides/${ride.ride_proposal_id}/actualRide`, reqBody, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
        .then(history.push(ROUTES.tripRating));
    } catch (err) {
      console.log(err);
    }
  };

  const handleAnimations = () => {
    if (detailsOpen && needsToAnimateOut) {
      setDetailsOpen(false);
      setNeedsToAnimateOut(false);
    }
  };

  const getRouteShapeDate = async () => {
    try {
      let routeShapeData = (
        await axios.get(`${config.blaiseApiUrl}/rides/${ride.ride_proposal_id}/lockedInRoute`, {
          headers: { Authorization: `Bearer ${accessToken}` }
        })
      ).data;

      if (routeShapeData) {
        setLockedInRoute(routeShapeData.lockedInRouteShape);
        setUndeterminedRouteShape(routeShapeData.undeterminedRouteShape);
      }
    } catch (error) {
      console.log('Error getting locked in route' + error);
    }
  };

  useEffect(() => {
    // Map centre set as middle of origin and dest
    const centreLon = (Number(ride.origin_lon) + Number(ride.dest_lon)) / 2;
    const centreLat = (Number(ride.origin_lat) + Number(ride.dest_lat)) / 2;
    setMapCentre([centreLon, centreLat]);

    // Get user's location from browser navigator
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        setUserLocation([position.coords.longitude, position.coords.latitude]);
      });
    } else {
      openSnackbar(t('console.genericError'), colors.red);
    }

    getRouteShapeDate();

    (async () => {
      await getWalkingLines();
    })();

    try {
      let ref = Firebase.database().ref('/bus_locations/' + ride.bus_id);
      ref.on('value', (snapshot) => {
        if (
          snapshot.val() &&
          snapshot.val().longitude &&
          snapshot.val().latitude &&
          snapshot.val().bearing
        ) {
          setBusLocation([Number(snapshot.val().longitude), Number(snapshot.val().latitude)]);
          setBusBearing(snapshot.val().bearing);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const closeSnackbar = (reason) => {
    if (reason !== 'clickaway') {
      setSnackbar({ ...snackbar, open: false });
    }
  };

  const openSnackbar = (snackbarString, color) => {
    setSnackbar({ open: true, message: snackbarString, color: color });
  };

  return (
    <StyleRoot>
      <CustomSnackbar
        message={snackbar.message}
        open={snackbar.open}
        onClose={() => closeSnackbar()}
        snackbarColor={snackbar.color}
      />
      <div display="flex">
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={detailsOpen ? { backgroundColor: colors.blaiseGray, opacity: '30%' } : {}}
        >
          {mapCentre && (
            <Map
              style={'mapbox://styles/mapbox/light-v10?optimize=true'}
              className="map"
              zoom={[12]}
              center={mapCentre}
              containerStyle={{ height: window.innerHeight - 80, width: window.innerWidth }}
            >
              {ride.secret_code && (
                <Box
                  flexDirection="column"
                  display="flex"
                  justifyContent="center"
                  style={{
                    top: '10%',
                    right: '5%',
                    position: 'absolute',
                    margin: 'auto',
                    backgroundColor: 'white',
                    borderRadius: 10,
                    boxShadow: '2px 2px 5px 2px #BBBBBB',
                    padding: 15
                  }}
                  flexWrap="wrap"
                >
                  <Typography variant="h1" align="center">
                    {' '}
                    {String.fromCodePoint(ride.secret_code.replace('U+', '0x'))}{' '}
                  </Typography>
                  <Typography variant="h5" align="center">
                    Secret Code
                  </Typography>
                </Box>
              )}

              {userLocation && (
                <Marker coordinates={userLocation} anchor="bottom">
                  <FontAwesomeIcon
                    icon={faCircle}
                    mask={faCircle}
                    color={colors.white}
                    transform="shrink-4"
                    size="1x"
                    style={{ backgroundColor: colors.blaiseGreen, borderRadius: 50 }}
                  />
                </Marker>
              )}
              {originCoords && (
                <Marker coordinates={originCoords} anchor="bottom">
                  <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaisePurple} size="2x" />
                </Marker>
              )}
              {destCoords && (
                <Marker coordinates={destCoords} anchor="bottom">
                  <FontAwesomeIcon icon={faMapMarkerAlt} color={colors.blaiseRed} size="2x" />
                </Marker>
              )}
              {pickupCoords && dropoffCoords && (
                <Layer
                  type="circle"
                  id="markers"
                  paint={{
                    'circle-color': colors.white,
                    'circle-stroke-width': 1.5,
                    'circle-stroke-color': colors.blaiseGray,
                    'circle-stroke-opacity': 1,
                    'circle-radius': 2
                  }}
                >
                  <Feature coordinates={pickupCoords} />
                  <Feature coordinates={dropoffCoords} />
                </Layer>
              )}
              {busLocation && (
                <Marker
                  coordinates={busLocation}
                  anchor="bottom"
                  style={{ transform: [{ rotate: '14deg' }] }}
                >
                  <img
                    src={busIcon}
                    style={{ width: 50, transform: `rotate(${busBearing}deg)` }}
                    alt={t('altText.bus_icon')}
                  ></img>
                </Marker>
              )}

              {route1 && (
                <Layer type="line" paint={linePaint} layout={lineLayout}>
                  <Feature coordinates={route1} />
                </Layer>
              )}

              {route2 && (
                <Layer type="line" paint={linePaint} layout={lineLayout}>
                  <Feature coordinates={route2} />
                </Layer>
              )}

              {lockedInRoute && (
                <Layer
                  key={'lockedIn'}
                  type="line"
                  paint={{ 'line-width': 2, 'line-color': colors.blaiseGreen }}
                >
                  <Feature coordinates={lockedInRoute} />
                </Layer>
              )}

              {undeterminedRouteShape && (
                <Layer type="line" paint={busLinePaint} layout={lineLayout}>
                  <Feature coordinates={undeterminedRouteShape} />
                </Layer>
              )}

              <Box
                flexDirection="row"
                display="flex"
                justifyContent="center"
                style={{ bottom: '5%', position: 'absolute', margin: 'auto', width: '100%' }}
                flexWrap="wrap"
              >
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  style={{
                    width: '15%'
                  }}
                  onClick={() => {
                    setDetailsOpen(true);
                  }}
                >
                  {t('onTrip.tripDetails')}
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  variant="contained"
                  style={{
                    width: '15%'
                  }}
                  onClick={() => {
                    handleRouteEnd();
                  }}
                >
                  {t('onTrip.endTrip')}
                </Button>
              </Box>
            </Map>
          )}
        </Box>

        {(detailsOpen || needsToAnimateOut) && (
          <Box flexDirection="row" display="flex" justifyContent="center" flexWrap="wrap">
            <div
              style={needsToAnimateOut ? style.slideOutDown : style.slideInUp}
              onAnimationEnd={() => handleAnimations()}
            >
              <div style={style.destStyle}>
                <Destinations data={ride} style={{ width: 3 }} type="upcoming"></Destinations>
              </div>
              <Box
                flexDirection="row"
                display="flex"
                justifyContent="center"
                style={{ bottom: '6%', position: 'absolute', margin: 'auto', width: '100%' }}
                flexWrap="wrap"
              >
                <Button
                  aria-labelledby="Close trip details"
                  color="primary"
                  type="button"
                  variant="contained"
                  style={{ width: '20%' }}
                  onClick={() => {
                    setNeedsToAnimateOut(true);
                  }}
                >
                  OK
                </Button>
              </Box>
            </div>
          </Box>
        )}
      </div>
    </StyleRoot>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('common')(TripProcess));
